function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}
function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}


class Panels3d {
  constructor(args) {
    this.data = args.data;
    this.images = args.images;
    this.$container = args.$container;

    this.$panels = $();
    this.maxPanels = 8;
    this.tweens;

    this.currentYRotation = 0;
    this.interval, this.interval2;
    this.animationRequest;

    this.perspective = 150;
    this.panelWidth = 150;
    this.panelZMin = -100;
    this.panelZMax = 50;

    if (!this.$container ) {
      this.$container = $(`
      <div class="panels-3d">
      </div>
      `);
      $('#Partner-Spotlight').append(this.$container);
    }
    if (window.innerWidth < 750) {
      this.panelWidth = 100;
    }
    this.$container.addClass('panels-3d');
    this.$container.css({'perspective':this.perspective, 'transform':'rotateY(0)'});

    this.i = 0;

    // this.$container.append(`
    //   <div class="title" style="transform:translate3d(-50%, -50%, 0px); left:50%; position: absolute">
    //     <h1>20-year Partner Spotlight</h1>
    //     <p class="subtitle">Learn about the work of our longest-term partners.</p>
    //   </div>
    // `)

    for (this.i = 0; this.i < this.maxPanels; this.i++ ) {
      this.makePanel(this.data[this.i % this.data.length], this.i);  
    }



    this.animationRequest = requestAnimationFrame(this.movePanelsUp.bind(this))
    // setTimeout( function () {

    // }.bind(this), 1000)
    $(window).on('resize', function () {
      if (window.innerWidth < 750) {
        this.panelWidth = 100;
      }
      else {
        this.panelWidth = 150;
      }
    }.bind(this))
  }

  getPanelStartPosition (inViewport) {
    var width = this.panelWidth;
    var height = 100; // approximate
    var translateZ = getRandomIntInclusive(this.panelZMin, this.panelZMax);
    // translateZ = this.panelZMin;

    var ratio = (this.perspective + (translateZ*-1))/this.perspective;
    var ratioAtZMin = (this.perspective + (this.panelZMin*-1))/this.perspective;
    var ratioAtZMax = (this.perspective + (this.panelZMax*-1))/this.perspective;

    // var minLeftFromZero = (ratio-1)/-2;
    // var maxLeftFromZero = minLeftFromZero*-1 + 1;

    // var minBottomFromZero = inViewport ? (ratio-1)/-2 : (ratioAtZMin-1)/-2;
    // var maxBottomFromZero = minBottomFromZero*-1 + 1;

    // var minLeftPercent = minLeftFromZero * 100;
    // var maxLeftPercent = maxLeftFromZero * 100;
    
    var minTranslateX = window.innerWidth / 2 * (ratio * -1);
    var minTranslateY = inViewport ? (window.innerHeight / 2 * ratio) : (window.innerHeight / 2 * ratioAtZMin);


    var maxTranslateX = window.innerWidth / 2 * ratio - width;
    var maxTranslateY = window.innerHeight / 2 * (ratio * -1)

    if (window.innerWidth >= 750) {
      minTranslateX = 0;
      maxTranslateX = $('#Partner-Spotlight .content')[0].getBoundingClientRect().width / 2 * ratio - width;
    }
    var translateX = getRandomIntInclusive(minTranslateX, maxTranslateX);
    var translateY = minTranslateY;      
    if (inViewport) {
      translateY = getRandomIntInclusive(minTranslateY, maxTranslateY)
    }
    return {
      translateX: translateX,
      translateY: translateY,
      translateZ: translateZ
    }
  }
  makePanel (datum, index) {
    var position = this.getPanelStartPosition(index <= 4 ? true : false);
    var id = 'panel-3d-'+datum.title.replace(/\W/g,'-')+index;
    
    var $panel = $(`
        <a 
          id="${id}" 
          class="panel panel-3d link" 
          href="${datum.url}"
          target="_new"
          style="
          width:${this.panelWidth}px; transform:translate3d(${position.translateX}px, ${position.translateY}px, ${position.translateZ}px)"
        >          
            <img class="logo" src="${this.images[datum.image]}"/>
        </a>
    `);
    this.$container.append($panel);
    this.$panels = this.$panels.add($panel);


    $panel.on('mouseover', function () {
      this.$container.addClass('hover');
      $panel.addClass('hover');
    }.bind(this))
    .on('mouseout', function () {
      this.$container.removeClass('hover');
      $panel.removeClass('hover');
    }.bind(this))
  }

  movePanelsUp (timestamp) {
    var data = this.data;
    this.$panels.each(function (index, element) {
      var $panel = $(element);
      var xValue = $panel.css('transform').match(/matrix.*\((.+)\)/)[1].split(', ')[12];
      var yValue = $panel.css('transform').match(/matrix.*\((.+)\)/)[1].split(', ')[13];
      var zValue = $panel.css('transform').match(/matrix.*\((.+)\)/)[1].split(', ')[14];

      var boundingClientRect = $panel[0].getBoundingClientRect();

      if (timestamp > 3000 && !$panel.hasClass('hover')) {
        $panel.css({
          'transform':`translate3d(${xValue}px, ${yValue-1}px, ${zValue}px)`
        });
      }

      if ( boundingClientRect.bottom < -100 ) {
        var position = this.getPanelStartPosition();
        $panel.css({
          'transform': `translate3d(${position.translateX}px, ${position.translateY}px, ${position.translateZ}px)`
        })
      }

      if ( boundingClientRect.bottom > window.innerHeight*.75 || boundingClientRect.top < window.innerHeight*.25 ) {
        if ( !$panel.hasClass('fading')) {
          $panel.addClass('fading');
        }
      } 
      else {
        if ( $panel.hasClass('fading') ) {
          $panel.removeClass('fading')
        }
      }

    }.bind(this))
    this.animationRequest = requestAnimationFrame(this.movePanelsUp.bind(this));
  }
  destroyAll () {
    clearInterval(this.interval);
    cancelAnimationFrame(this.animationRequest)
    this.$panels.remove();
    this.$panels = $();
    // gsap.killTweensOf('.panel-3d');
    $(window).off('resize');
  }

}

window.Panels3d = Panels3d;
