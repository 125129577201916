import 'regenerator-runtime/runtime';
import '/js/import-jquery.js';
import { select, selectAll, create } from 'd3-selection';
import { max } from 'd3-array';
import { scaleLinear, scaleOrdinal } from 'd3-scale';
/*
NOTE: this library might have been modified as
*/
import 'pagepiling.js';
import '/js/panels-3d.js';
// import { Fancybox } from "@fancyapps/ui";

// parcel cannot import this, could not find reason
// using cdn in index.html instead
// import Swiper from 'swiper';
// import 'swiper/css';

import 'pagepiling.js/dist/jquery.pagepiling.css';
// import "@fancyapps/ui/dist/fancybox.css";
import '/css/arcus.scss';

import grantByYearData from '/assets/grant-by-year/grant-by-year.json';
import grantByYearImages from '../assets/grant-by-year/*.jpg';
import grantByYearImagesFull from '../assets/grant-by-year/uncompressed/*.jpg';

import section03Data from '../assets/section-03/section-03.json';
import section03Videos from '../assets/section-03/*.mp4';
import section03Images from '../assets/section-03/*.jpg';

import financials2020Data  from '../assets/2020-financials/2020-financials.json';
import financials2020Images  from '../assets/2020-financials/*.jpg';

import partnerData from '../assets/partner-orgs/partner-orgs.json';
import partnerLogos from '../assets/partner-orgs/*.jpg';

const sections = [
  'Home', 
  'Reflections', 
  'Conversations', 
  'Deep-Partnerships', 
  '20-Years', 
  '2020-Financials', 
  'Blog',
  'Partner-Spotlight',
  // 'Credits',
  'Contact-Us'
  ];

window.sections = sections;

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
});

var partnerSpotlight = function () {
  var data = partnerData;
  var images = partnerLogos;
  var $section = $('.partner-spotlight');
  var swiper;
  $section.find('.swiper > .swiper-wrapper').append(
    data.map(function (datum, index) {
      return $(`
        <div class="swiper-slide item slide-0${index}">
          <a href="${datum.url}" target="_new">
            <img src="${images[datum.image]}" alt="${datum.title}"/>
          </a>
        </div>
      `)
    })
  );
  swiper = new Swiper('#Partner-Spotlight .swiper', {
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    effect: "coverflow",
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 400,
      modifier: 1,
      slideShadows: true,
    },
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
      // pauseOnMouseEnter: true
    },
    allowtouchmove: false,
    // on: {
    //   slideChangeTransitionStart: function (swiper) {
    //     $('.spotlight').slideUp().delay(250).slideDown();
    //     // $('.spotlight').animate({
    //     //   opacity: 0,
    //     // }, {
    //     //   duration: 50,
    //     // })
    //     // .delay(250)
    //     // .animate({
    //     //   opacity: 1          
    //     // }, {
    //     //   duration: 250
    //     // })
    //   },
    //   slideChangeTransitionEnd: function (swiper) {
    //     var $slides = $(swiper.el).find('.swiper-slide');
    //     var $activeSlide = $slides.eq(swiper.activeIndex);
        
    //   }
    // }
    
  });
  $('#Partner-Spotlight .swiper').find('.item>a').on('click', function (event) {
    var index = $(event.currentTarget).closest('.item').index();
    if ( !$(this).closest('.item').hasClass('swiper-slide-active') ) {
      event.preventDefault();
      swiper.slideTo( index );
    }
  })  
}
var deepPartnershipSection = function () {
  var $deepPartnershipSection = $('#Deep-Partnerships');
  var $swiperContainer = $deepPartnershipSection.find('.swiper');

  

  const swiper = new Swiper('#Deep-Partnerships .swiper', {
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    effect: "coverflow",
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 400,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: '#Deep-Partnerships .swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    // slideToClickedSlide: true,
    on: {
      init : function (swiper) {
        // firefox bug:
        if (navigator.userAgent.indexOf('Firefox') > -1) {
          $(swiper.el).find('.swiper-wrapper').css({
            // 'transform-style':'flat'
            'width':0
          })
          $(swiper.el).find('.item').css({
            'transform-style':'preserve-3d'
          })
        }
        $(swiper.el).on('focus', function () {
          swiper.keyboard.enable()
          $.fn.pagepiling.setKeyboardScrolling(false);
          
        }).on('blur', function () {
          swiper.keyboard.disable()
          $.fn.pagepiling.setKeyboardScrolling(true);
        })          
      }
      // slideChangeTransitionEnd: function (swiper) {
      //   var $slides = $(swiper.el).find('.swiper-slide');
      //   var $activeSlide = $slides.eq(swiper.activeIndex);
      //   var images = [];
      //   $slides.each(function (index) {
      //     var $link = $(this).find('[data-fancybox]');
      //     images.push({
      //       src: $link.attr('href'),
      //       type: 'image'
      //     })
      //   })
      //   Fancybox.show(images, {startIndex: swiper.activeIndex});
      // }
    }
  });
  $swiperContainer.find('.item>a').on('click', function (event) {
    var index = $(event.currentTarget).closest('.item').index();
    if ( !$(this).closest('.item').hasClass('swiper-slide-active') ) {
      event.preventDefault();
      swiper.slideTo( index );
    }
  })


}
var section03Carousel = function (data) {
  var $section03Carousel = $('#video-carousel-01');
  var $carouselInner = $section03Carousel.find('.carousel-inner');
  var id = $section03Carousel.attr('id');
  var itemHtml = function (d,i) {
    var src = d.src;
    var thumb = d.thumbnail;
    d.alt = d.alt || "";
    if (src === undefined) src = section03Videos[i+''];
    if (thumb == undefined) thumb = section03Images[i+''];
    return `
      <div class="image-wrapper">
        <a class="video-link" href="${src}" data-fancybox>
          <span class="sr-only">${d.title}</span>
          <img src="${thumb}" alt="${d.alt}"/>
          <svg class="video-player" width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.800049" width="58.2" height="58.2" rx="29.1" />
          <path d="M21.3401 19.5787L39.1487 30.8701L21.3401 42.1615V19.5787Z" fill="white"/>
          </svg>
        </a>
      </div>
    `
  }

  select($carouselInner[0])
    .selectAll('div')
    .data(data)
    .join('div')
      .classed('item swiper-slide', true)
      .attr('data-type', (d)=>d.type)
      .attr('data-text', (d)=>d.text)
      .html(itemHtml)


  const swiper = new Swiper('#video-carousel-01 .swiper', {
    direction: 'horizontal',
    loop: true,
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      600: {
        slidesPerView: 1.5,
      },
      750: {
        slidesPerView: 3
      },
      1100: {
        slidesPerView: 4
      },
      1420: {
        slidesPerView: 6
      }
    },
    initialSlide:2,    
    // Navigation arrows
    navigation: {
      nextEl: '#video-carousel-01 .swiper-button-next',
      prevEl: '#video-carousel-01 .swiper-button-prev',
    },
    pagination: {
      el: '#video-carousel-01 .swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    on: {
      init: function (swiper) {
        $(swiper.el).on('focus', function () {
          swiper.keyboard.enable()
          $.fn.pagepiling.setKeyboardScrolling(false);
          
        }).on('blur', function () {
          swiper.keyboard.disable()
          $.fn.pagepiling.setKeyboardScrolling(true);
        });        
      },
      slideChange: function (swiper) {
        var data = $(swiper.slides[swiper.activeIndex]).data();
        var type = data.type;
        var $heading = $section03Carousel.find('.heading');
        var $bottomText = $section03Carousel.find('.bottom-text');
        var $mapWrapper = $section03Carousel.find('.map-wrapper');
        $bottomText.animate({
          opacity:0
        }, 
        // 'ease-in-out',
        function () {
          
            $(this).find('p').html(data.text);
            $(this).animate({opacity:1},800)
         }
        )
        $mapWrapper.find('.map-overlay:not(.'+type+')').removeClass('active');
        $mapWrapper.find('.map-overlay.'+type).addClass('active');
      }
    }
  });

  $section03Carousel.find('.video-link').on('click', function (event) {
    var $link = $(this);
    var index = $link.closest('.item').index();
    if ( !$(this).closest('.item').hasClass('swiper-slide-active')) {
      event.preventDefault();
      if (window.innerWidth >= 750) {
        swiper.slideTo( index );
        setTimeout(function () {
          // console.log($link);
          Fancybox.show([{
            src: $link.attr('href'),
            type: 'image'
          }])
        }, 400);
      }
    }
  })
}

var grantAmountByYear = function (data) {

  var $grantAmountGraph = $('#grant-amount-by-year');
  var $showAmount = $grantAmountGraph.find('.amount');
  var showAmountOd;
  var barChart = select($grantAmountGraph[0]).select('.bar-chart');
  var yScale = scaleLinear().domain( [0,max(data, d => d.amount)] ).range( [0, 205] )

 
  var chartHtmlItem = function (d,i) {
    var height = yScale(d.amount);
    return `
      <div class='bar-wrapper'>
        <div class='bar' style='width:15px; height:${height}px;'>
        </div>
        <div class='year-wrapper'>
          <span class='year'>${d.year}</span>
        </div>
      </div>
      ${
        d.images.map( function(item, index, array) {
          var image = item.image;
          var imageFull = item.imageFull || item.image;
          var caption = item.caption;
          var alt = item.alt || "";
          var style = "";
          if (d.images.length === 1) {
            style = ` style="top:auto; bottom:${height+55}px;" `
          }
          return `
            ${ image ? 
            `
            <a ${style} class='year-image ${d.images.length===4 ? `image-4` : ``}' href="${imageFull}" ${caption ? `data-caption='${caption}'` : ``} data-fancybox="year-${d.year}" data-caption2="${d.year}">
            <img src='${image}' alt="${alt}" />
            </a>
            ` :
            ''
            }
          `
        }).join('')
      }

    `;
  }
  var updateAmount = function(amount) {
    // amount = formatter.format(amount);
    // $showAmount.text(amount);

    if ( !showAmountOd ) {
      showAmountOd = new Odometer({
        el: $showAmount[0],
        value: amount,
        // animation: 'count',
        // Any option (other than auto and selector) can be passed in here
        format: '(,ddd)',
        duration:600,
        theme: 'default'
      });
    }
    else {
      showAmountOd.update(amount);
    }
  }

  data = data.sort((a,b) => a.year - b.year );
  data = data.map(function ( d ) {
    var arr = Array.from(Array(4).keys());
    var images2 = [];
    arr.forEach(function (index) {
      var image;
      var index1 = index+1;
      var item = {}
      var imageKey = 'Nav5_'+d.year+'_'+index1;
      // Nav5_2000_1_EEOP
      image = grantByYearImages[imageKey];
      if (d.images && Array.isArray(d.images) && d.images[index]) {
        item = d.images[index];
        if (d.images[index].image) {
          imageKey = d.images[index].image;
          image = grantByYearImages[imageKey];
        }                
      }
      
      if (image) {
        item.image = image;
        if ( grantByYearImagesFull && grantByYearImagesFull[imageKey] ) {
          item.imageFull = grantByYearImagesFull[imageKey];
        }
        images2.push(item);
      }
    });
    d.images = images2;
    return d;
  });
  
  $grantAmountGraph.find('.slider').attr('max', data.length);

  barChart.selectAll("div")
    .data(data)
    .join("div")
      .classed('item swiper-slide', true)
      .classed('image-4', (d)=>d.images.length === 4)
      .attr('data-year', (d)=>d.year )
      .attr('data-amount', (d)=>d.amount )
      .html(chartHtmlItem)

  const swiper = new Swiper('#grant-amount-by-year.swiper', {
    direction: 'horizontal',
    loop: false,
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 5,
    breakpoints: {
      750: {
        slidesPerView: 15
      }
    },
    initialSlide: data.length-1,  

    // Navigation arrows
    navigation: {
      nextEl: '#grant-amount-by-year .swiper-button-next',
      prevEl: '#grant-amount-by-year .swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
      el: '#grant-amount-by-year .swiper-scrollbar',
      draggable: true,
      snapOnRelease: true,
      dragSize:25
    },


    on:{
      init: function (swiper) {
        var amount = $(swiper.wrapperEl).children().eq(swiper.activeIndex).data().amount;
        updateAmount(amount);
        $(swiper.el).on('focus', function () {
          swiper.keyboard.enable()
          $.fn.pagepiling.setKeyboardScrolling(false);
          
        }).on('blur', function () {
          swiper.keyboard.disable()
          $.fn.pagepiling.setKeyboardScrolling(true);
        })
      },
      slideChange: function (swiper) {
        var $items = $(swiper.wrapperEl).children();
        var amount = $items.eq(swiper.activeIndex).data().amount;
        updateAmount(amount);

        $items.css({'z-index':0});
        $items.eq(swiper.activeIndex).css({'z-index':10});
      }
    }
  });

  $grantAmountGraph.find('.swiper-slide .bar, .swiper-slide .year-wrapper').on('click', (event) => {
    var index = $(event.currentTarget).closest('.item').index();
    swiper.slideTo( index );
  });
  Fancybox.bind('.year-image[data-fancybox]', {
    mainClass: 'bar-graph-with-images',
    click: null,
    preload: 4,
    on: {
      ready: function (fancybox) {
        var index = $(fancybox.$carousel).find('.fancybox__slide.is-selected').index();
        var caption2 = $(fancybox.items[index].$trigger).data().caption2
        $(fancybox.$carousel).find('.fancybox__slide').prepend(`
          <div class="fancybox__caption fancybox__caption2 " style="order:-1;">${caption2}</div>
        `)
        $(fancybox.$container).on('click', function (event) {
          if ( $(event.target).closest('.fancybox__slide').length ) {
            if (event.clientX < window.innerWidth/2) {
              fancybox.prev();
            }
            else {
              fancybox.next();
            }
          }
        })
      },
      load: function (fancybox, slide) {
        var index = $(slide.$el).index();
        var caption2 = $(slide.$trigger).data().caption2;
        var $caption = $(slide.$el).find('.fancybox__caption:not(.fancybox__caption2)');
        var $caption2 = $(slide.$el).find('.fancybox__caption2');

        var $slideImage = $(slide.$el).find('.fancybox__image');
        var $triggerImage = $(slide.$trigger).find('img');

        if ($caption2.length < 1 ) {
          $(slide.$el).prepend(`
            <div class="fancybox__caption fancybox__caption2 " style="order:-1;">${caption2}</div>
          `)
        }
        if ($caption.length < 1) {
          $(slide.$el).append(`<div class="fancybox__caption"></div>`)
        }

      $slideImage.attr('alt', $triggerImage.attr('alt'));
      },
      reveal: (fancybox, slide) => {
        $.fn.pagepiling.setKeyboardScrolling(false);
      },
      destroy: () => {
        $.fn.pagepiling.setKeyboardScrolling(true);
      }
    }
  })

  // $grantAmountGraph.find('.year-image').on('click', function (event) {
  //   var gallery = $(event.currentTarget).data('fancybox');
  //   var $links = $grantAmountGraph.find('[data-fancybox="'+gallery+'"]');
  //   var images = [];
  //   $links.each(function(index) {
  //     images.push({
  //       src: $(this).attr('href'),
  //       type: 'image'
  //     })
  //   })
  //   event.preventDefault();
  //   event.stopPropagation();
  //   console.log('click ', gallery, $links, images);
  //   Fancybox.show(images);
  // })
}

var financials2020 = function (data) {
  var totalExpensesAmount = 0;
  var totalGrantMakingAmount = 0;

  var dataNested = data.reduce(function(previousValue, currentValue){
    totalExpensesAmount += currentValue.amountDollars;
    if (currentValue.type === 'campaign') {
      totalGrantMakingAmount += currentValue.amountDollars;
    }
    if (currentValue.nest) {
      if (previousValue[previousValue.length-1].type !== 'parent') {
        previousValue.push({
          type:'parent',
          amountDollars: 0,
          subCategories:[]
        });
      }
      previousValue[previousValue.length-1].amountDollars+=currentValue.amountDollars;
      currentValue.parent= previousValue[previousValue.length-1];
      previousValue[previousValue.length-1].subCategories.push(currentValue)
    }
    else {
      previousValue.push(currentValue)
    }
    return previousValue;
  }, []);

  var $container = $('#financials2020');
  var box = select($container.find('.box')[0]);
  var legendWrapper = select($container.find('.legend-wrapper')[0]);
  var colors = ['orange', 'green', 'purple', 'medGray', 'white'];
  var colorScale = scaleOrdinal().domain(data).range(colors)


  box.append('div')
    .classed('wrapper', true)
    .attr('aria-hidden', "true")
    .selectAll('div')
    .data(dataNested)
    .join('div')
      .classed('bar',true)
      .classed('segment', (d)=>!Array.isArray(d.subCategories))
      .classed('container',(d)=>Array.isArray(d.subCategories))
      .style('height', '100%')
      .style('width', (d)=>(d.amountDollars/totalExpensesAmount*100)+'%')
      .attr('data-percent', (d,i,n)=>parseFloat(n[i].style.width).toFixed(0))
      .attr('data-title', (d)=>d.title?d.title:false)
      .selectAll('div')
        .data( (d) => d.subCategories ? d.subCategories : [] )
        .join('div').classed('bar',true)
        .classed('segment', (d)=>!Array.isArray(d.subCategories))
        .style('width','100%')
        .style('height',(d)=>(d.amountDollars/d.parent.amountDollars*100)+'%')
        .attr('data-percent', (d,i,n) => (d.roundDown ? Math.floor(d.amountDollars/totalExpensesAmount*100) : (d.amountDollars/totalExpensesAmount*100).toFixed(0)) )
        .attr('data-title', (d)=>d.title?d.title:false)

  legendWrapper.select('.legend-wrapper-inner').append('ul').classed('legend',true)
    .selectAll('li')
    .data(data)
    .join('li')
    .html((d,i)=>{
      var amount = formatter.format(d.amountDollars);
      return `
      <div class='circle bg-${colorScale(d)}'></div>
      <a data-src="#financials2020-dialog-${i}" class='dialog-link legend-item-text' data-fancybox='financials2020'>
        <span class="title">${d.title}</span>
        
      </a>
      <div id="financials2020-dialog-${i}" class="financials2020-dialog modal-dialog" style="display:none;">
        <h1>2020 Financials</h1>
        <div class="expenses"><p>Total Expenses</p></div>
        <div class="grantmaking"><p>Total Grantmaking</p></div>
        <div class="chart-wrapper">
          <div class="box-wrapper">
            <div class="box bg-${colorScale(d)}">
              <span class="sr-only">
                A graphic depicting the amount dedicated to ${d.title}: ${amount}${!isNaN(d.amountGrants) ? `, with ${d.amountGrants} grants` : ``}
              </span>
              <div class="wrapper" aria-hidden="true">
                <p class="amountDollars">${amount}</p>
                ${!isNaN(d.amountGrants) ? `<p class="amountGrants">${d.amountGrants} Grants</p>` : ``}
              </div>
            </div>
          </div>
          <div class="legend-wrapper">
            <ul class="legend">
            ${
              data.map(function (element,index,arr) {
                var amount = formatter.format(element.amountDollars);
                return `
                <li class="${index === i ? `active` : ``}">
                  <div class="circle bg-${colorScale(element)}"></div>
                  <a href="#financials2020-dialog-${index}" class='dialog-link dialog-link--inner legend-item-text'>
                    <span class="title">${element.title}</span>
                  </a>
                </li>
                `;
              }).join('')
            }
            </ul>
          </div>
        </div>
      </div>
      `;
    });

  $container.find('.segment')
    .addClass((i,currentClassName)=>'bg-'+colorScale(data[i]))
    .addClass('dialog-link')
    .attr('data-src', (i)=>`#financials2020-dialog-${i}`)
    .attr('data-fancybox', 'financials2020')
    .append(function (i) {
       return `
         <p class="text"><span>${$(this).data('percent')}<span style="font-weight:700">%</span></span></p>
       `
     });

  $container.find('.dialog-link--inner').on('click', function (e) {
    var index = $(this).closest('li').index();
    console.log( 'click')
    e.preventDefault();
    Fancybox.getInstance().jumpTo(index);
  });
  // $container.find('.dialog-link').on('click', function(e) {
  //   e.preventDefault();
  //   Fancybox.close(true);
  //   Fancybox.show([{ src: $(this).attr('href'), type: "inline",}], {mainClass:"dialog-financials2020", animated:true})
  // })
  Fancybox.bind('[data-fancybox="financials2020"]', 
    {
      mainClass:"dialog-financials2020", 
      animated:true,
      // closeButton: 'outside',
      infinite: true,
      preload: 5,
      on: {
        ready: (fancybox) => {
          $.fn.pagepiling.setKeyboardScrolling(false);
        },
        reveal: (fancybox, slide) => {
          $(slide.$content).find('.dialog-link--inner').off('click');
          $(slide.$content).find('.dialog-link--inner').on('click', function (e) {
            var index = $(this).closest('li').index();
            console.log( 'click')
            e.preventDefault();
            Fancybox.getInstance().jumpTo(index);            
          });
        },
        destroy: () => {
          $.fn.pagepiling.setKeyboardScrolling(true);
        }
      }      
    });
  $container.find('.expenses').append('<p>'+formatter.format(totalExpensesAmount)+'</p>');
  $container.find('.grantmaking').append('<p>'+formatter.format(totalGrantMakingAmount)+'</p>');

}

var afterSectionsRender = function (anchorLink, index) {
  var $main = $('#main');
  var $show = $('body');
  var $sections = $main.find('.section');
  var $pageNumber = $main.find('.page-number');
  index = index || 1;

  // $main.show();
  $pageNumber.find('.outOf').text('/'+$sections.length);
  $pageNumber.find('.number').text(index);
}

$(document).ready(function() {
  var panels;
  $('.menu-toggle, .menuLink, .homeLink').on('click', function () {
    //toggle menu open close
    $('.menu-toggle').toggleClass('-menu-open');
    $('#mainMenu').toggleClass('-menu-open');
  })

  $('#main').pagepiling({
    anchors:sections,
    navigation: {
      'textColor': '#fff',
      'bulletsColor': '#fff',
      'position': 'right'
      // 'tooltips': sections
    },
    keyboardScrolling: true,
    menu: '#mainMenu',
    touchSensitivity: 15,
    normalScrollElementTouchThreshold: 1,
    afterRender: afterSectionsRender,
    afterLoad: afterSectionsRender,
    onLeave: function () {
      setTimeout(function () {
        $('.pp-scrollable').css('overflow-y', 'auto');
      },5)
    },

    normalScrollElements: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? '.swiper, .fancybox__container' : null
  });

  $('.pp-nav-vertical-up').on('click', function () {
   $.fn.pagepiling.moveSectionUp();;

  });;
  $('.pp-nav-vertical-down').on('click', function () {
    $.fn.pagepiling.moveSectionDown();
  })


  deepPartnershipSection();
  section03Carousel(section03Data);
  grantAmountByYear(grantByYearData);
  financials2020(financials2020Data);
  partnerSpotlight();
  
  Fancybox.defaults.closeButton = 'outside';
  Fancybox.bind("[data-fancybox]", {
    on: {
      reveal: (fancybox, slide) => {
        $.fn.pagepiling.setKeyboardScrolling(false);
      },
      destroy: () => {
        $.fn.pagepiling.setKeyboardScrolling(true);
      }
    },
  });
  Fancybox.bind(".credits-link[data-fancybox]", {
    mainClass: 'dialog-credits',
    // closeButton: 'outside',
    animated: false,
    on: {
      reveal: (fancybox, slide) => {
        console.log('credits clicked');
      }
    }
  });
  $('#toggle-video-pause').on('change', function () {
    if (this.checked) {
      $('.video-bg').each(function (index, element) {
        element.pause();
        element.classList.add('paused');
      })
    }
    else {
      $('.video-bg').each(function (index, element) {
        element.play();
        element.classList.remove('paused');
      })
    }

  })
  $('.pp-scrollable').on('scroll', function () {
    // console.log($(this).scrollTop());
    var $scrollable = $(this);
    if ($scrollable.scrollTop() === 0) {
      $scrollable.css('overflow-y', 'hidden');
      // console.log($(this));
      window.setTimeout(function () {
        $scrollable.css('overflow-y', 'auto');
      }, 750);
    }
  })
});

